<template>
    <div class="app-container">
        <el-card class="box-card" style="height: 82vh;">
            <div style="display: flex; align-items: center; padding: 10px 0;">
                <div style="margin-right: 20px"><el-switch v-model="horizontal"></el-switch> 横向</div>
                <div style="margin-right: 10px"><el-switch v-model="collapsable"></el-switch> 可收起</div>
                <!-- <div style="margin-right: 10px"><el-switch v-model="disaled"></el-switch> 禁止编辑</div> -->
                <!-- <div style="margin-right: 10px"><el-switch v-model="onlyOneNode"></el-switch> 仅拖动当前节点</div> -->
                <!-- <div style="margin-right: 10px"><el-switch v-model="cloneNodeDrag"></el-switch> 拖动节点副本</div> -->
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div style="margin-left: 20px;">背景色：</div>
                    <el-color-picker v-model="style.background" size="small"></el-color-picker>&nbsp;
                    <div style="margin-left: 20px;">文字颜色：</div>
                    <el-color-picker v-model="style.color" size="small"></el-color-picker>&nbsp;
                    <div style="margin-left: 20px;">搜索：</div>
                    <input type="text" v-model="keyword" placeholder="请输入搜索内容" @keydown.enter="filter" />
                </div>
            </div>
            

            <!-- 结构树 -->
            <div style="height: 70vh; border:1px solid #eee">
                <zm-tree-org ref="tree" :data="data" :disabled="disaled" :horizontal="horizontal" :collapsable="collapsable"
                    :label-style="style" :node-draggable="false" :default-expand-level="1" :only-one-node="onlyOneNode"
                    :clone-node-drag="cloneNodeDrag" 
                    :toolBar="toolBar" :filterNodeMethod="filterNodeMethod"  >
                    <!-- 自定义节点内容 -->
                    <template slot-scope="{node}">
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <img :src="node.id == '0'? logUrl : node.headImg" width="80px" height="80px" alt="image error">
                            <div style="margin-top: 10px;">{{ node.label }}</div>
                        </div>
                    </template>
                    <!-- 自定义展开按钮 -->
                    <template v-slot:expand="{ node }">
                        <div>{{ node.children.length }}</div>
                    </template>
                </zm-tree-org>
            </div>
        </el-card>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'UmsList',
    data() {
        return {
            logUrl: "https://yuyitang.oss-cn-shenzhen.aliyuncs.com/ytfile/upload/2022/04/22/69217696-014a-45a3-bc93-9ee67d06048d.png",
            toolBar: {
                scale: false
            },
            keyword: '',
            data: {},
            horizontal: false,
            collapsable: true,
            onlyOneNode: true,
            cloneNodeDrag: false,
            expandAll: true,
            disaled: false,
            style: {
                background: '#fff',
                color: '#5e6d82'
            },
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            api.getTreeData().then(res => {
                this.data = res
            })
        },
        filter(){
          this.$refs.tree.filter(this.keyword)
        },
        filterNodeMethod(value, data) {
          if (!value) return true;
          return data.label.indexOf(value) !== -1;
        },
    }
}

</script>